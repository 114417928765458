:root {
  --line-size: 0.7em;
}

.clock {
  height: 10em;
  width: 10em;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: calc(var(--line-size) + 0.1vw) solid #ccc;
  @apply: border-red-500;
  margin: 0 auto;
}

.clock::after {
  content: '';
  width: calc(var(--line-size) + 0.1vw);
  height: calc(var(--line-size) + 0.1vw);
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ccc;
}

.hour {
  position: absolute;
  width: calc(var(--line-size) + 0.02vw);
  height: 29%;
  background: #ccc;
  top: 22%;
  left: 46%;
  transform-origin: bottom;
  border-radius: 25%;
}

.min {
  position: absolute;
  width: calc(var(--line-size) + 0.02vw);
  height: 40%;
  background: #ccc;
  top: 11%;
  left: 46%;
  transform-origin: bottom;
  border-radius: 25%;
}
