@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&amp;display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* CHECKBOX TOGGLE SWITCH */
  /* @apply rules for documentation, these do not work as inline style */
  .toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
  }
  .toggle-checkbox:checked .toggle-label {
    @apply: bg-green-400;
  }

  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 13px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-negative;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-negative;
  }
}
